// Docs: https://docs.esign.eu/books/development/page/front-end-template#bkmrk-basicgooglemap

import Infobox from '../store-locator/infobox';
import googleMapsStyles from '../../constants/googleMapsStyles';
import { matchLarge } from '../../constants/defaults';

const defaultMapData = {
  holderId: 'contact-map',
  title: 'Febelauto',
  lat: 50.8392661,
  long: 4.4328198,
  externUrl: 'https://www.google.com/maps/place/Woluwedal+46%2Fbus+13,+1200,+1200+Sint-Lambrechts-Woluwe/@50.8392661,4.4328198,17z/data=!4m5!3m4!1s0x47c3db672ea6a3b9:0x67813462df998132!8m2!3d50.8392661!4d4.4350085',
};

export default class BasicGoogleMap {
  init(mapSettings = null) {
    const googleProperty = Object.getOwnPropertyDescriptor(window, 'google');

    if (!googleProperty || !googleProperty.value || !googleProperty.value.maps) {
      setTimeout(() => this.init(mapSettings), 100);
      return;
    }

    this.initMaps(mapSettings);
  }

  initMaps(mapSettings = null) {
    this.googleMaps = window.google.maps;
    this.baseUrl = window.baseUrl || '';

    // override default map data if param is set
    const mapData = mapSettings || defaultMapData;
    const holder = document.getElementById(mapData.holderId);

    if (holder && this.googleMaps) {
      const map = this.addMap(holder, mapData.lat, mapData.long);
      const marker = this.addMarker(
        map,
        mapData.lat,
        mapData.long,
        mapData.title,
        mapData.externUrl,
      );

      this.showInfobox(map, marker);
    }
  }

  addMap(holder, latitude, longitude) {
    const zoom = 15;
    const disable = true;
    const scroll = false;
    const styledMap = new this.googleMaps.StyledMapType(
      googleMapsStyles,
      { name: 'Styled Map' },
    );
    const mapCenter = new this.googleMaps.LatLng(latitude, longitude);
    const mapOptions = {
      zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: this.googleMaps.MapTypeId.ROADMAP,
    };
    const map = new this.googleMaps.Map(holder, mapOptions);

    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');

    this.googleMaps.event.addDomListener(window, 'resize', () => {
      map.setCenter(mapCenter);

      if (matchLarge.matches) {
        map.panBy(-300, -50);
      } else {
        map.panBy(0, 0);
      }
    });

    // Initial pan with delay to ensure map is properly loaded
    setTimeout(() => {
      if (matchLarge.matches) {
        map.panBy(-200, -50);
      } else {
        map.panBy(-100, 0);
      }
    }, 10);

    const address = window.location.href.indexOf('/fr/') > 0
      ? 'Boulevard de la Woluwe  46, boîte 13, 1200 Bruxelles <br>(Woluwe-Saint-Lambert)'
      : 'Woluwedal 46, bus 13, 1200 Brussel <br>(Sint-Lambrechts-Woluwe)';

    this.infobox = new Infobox({
      content:
        `<div class="grid">
          <div class="grid__item">
            <a href="tel:+3226810884">
              <svg class="icon icon-phone">
                <use xlink:href="#phone"></use>
              </svg>
              <span>+32 (0)2 681 08 84</span>
            </a>
          </div>
          <div class="grid__item">
            <a href="mailto:info@febelauto.be">
              <svg class="icon icon-email">
                <use xlink:href="#email"></use>
              </svg>
              <span>info@febelauto.be</span>
            </a>
          </div>
          <div class="grid__item">
            <a href="https://www.google.com/maps/place/Woluwedal+46%2Fbus+13,+1200,+1200+Sint-Lambrechts-Woluwe/@50.8392661,4.4328198,17z" target="_blank" rel="noopener">
              <svg class="icon icon-location-2">
                <use xlink:href="#location-2"></use>
              </svg>
              <span>${address}</span>
            </a>
          </div>
        </div>`,
      disableAutoPan: false,
      maxWidth: 180,
      pixelOffset: new this.googleMaps.Size(-175, -270),
      zIndex: null,
      boxStyle: {
        width: '350px',
        height: '185px',
      },
      closeBoxMargin: '0 0 2px 4px',
      closeBoxURL: '',
      infoBoxClearance: new this.googleMaps.Size(1, 1),
    });

    return map;
  }

  addMarker(map, latitude, longitude, title, externUrl) {
    const myLatlng = new this.googleMaps.LatLng(latitude, longitude);

    const markerIcon = {
      url: window.markerImg,
      size: new this.googleMaps.Size(48, 64),
      origin: new this.googleMaps.Point(0, 0),
      anchor: new this.googleMaps.Point(12, 32),
      scaledSize: new this.googleMaps.Size(24, 32),
    };

    const marker = new this.googleMaps.Marker({
      position: myLatlng,
      map,
      icon: markerIcon,
    });

    this.googleMaps.event.addListener(marker, 'click', () => {
      window.open(externUrl, '_blank');
    });

    return marker;
  }

  showInfobox(map, marker) {
    // Defensive check to ensure infobox exists and has required methods
    if (!this.infobox || typeof this.infobox.open !== 'function') {
      console.warn('InfoBox not properly initialized');
      return;
    }

    try {
      if (typeof this.infobox.close === 'function') {
        this.infobox.close();
      }
      this.infobox.open(map, marker);
    } catch (error) {
      console.error('Error showing InfoBox:', error);
    }
  }
}
